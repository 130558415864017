.table_Rates {
  display: flex;
  justify-content: center;
  padding: 17px;

  .Table_children {
    display: flex;
    flex-direction: row;
    :nth-last-child(n + 1) {
      flex: 1;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .rank {
      .rank_Title {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 0;
      }
      .rank_content {
        background: #ffff;
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
        padding-bottom: 20px;
        align-items: center;
      }
    }
  }
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        flex-direction: row-reverse;
        justify-content: space-between;
        background: #ffff;
        .ant-collapse-header-text {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
