.button_Edit {
  &-false {
    display: flex;
    justify-content: flex-end;
  }
  &-true {
    display: flex;
    justify-content: flex-end;
    opacity: 0;
  }
}
.ant-card-body {
  padding: 15px !important;
}
.profile {
  display: flex;
  flex-direction: column;
  // padding-top: 32px;
  .profile_avatar {
    justify-content: center;
  }

  .profile_avatar {
    text-align: center;
    display: flex;
    position: relative;
    margin-bottom: 35px;

    &-Image {
      &.true {
        opacity: 0.5;
      }
    }
    .button_upLoad {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      &.false {
        display: none;
      }
    }
  }
  .profile_content {
    padding: 15px !important;
    &-personal {
      margin-right: 20px;
    }
    &-job {
      margin-left: 20px;
    }
    h2 {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
    }

    .ant-form-item-label {
      label {
        font-weight: 700;
      }
    }
  }
}
.group_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  height: 32px;
}

.error_ImageUpload {
  &.false {
    display: none;
  }
}
