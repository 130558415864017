// @import '~antd/dist/antd.css';

* {
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.standard-form {
	.ant-row.ant-form-item {
		margin-bottom: 15px;
		.ant-col.ant-form-item-label {
			padding-bottom: 2px;
		}
	}
	.ant-row.ant-form-item.submit-btn {
		margin-bottom: 0;
	}
	.ant-row.ant-form-item.ant-form-item-has-error {
		margin-bottom: 5px;
	}
}
.text-truncate {
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}

// Fix giao diện chung
.ant-form-item-required::before {
	content: "" !important;
	display: none !important;
}

.ant-drawer-close {
	order: 1;
	color: #000;
}

.ant-drawer-header {
	box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
	z-index: 10000;
}

.field--required {
	span {
		color: red;
	}
}

.ant-message {
	z-index: 2001;
}

.standard-form {
	.ant-row.ant-form-item {
		margin-bottom: 15px;
		.ant-col.ant-form-item-label {
			padding-bottom: 2px;
		}
	}
	.ant-row.ant-form-item.ant-form-item-has-error {
		margin-bottom: 5px;
	}
}
.focus-btn {
	color: #40a9ff;
	background-color: #e6f7ff;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
}

.non-focus-btn {
	color: inherit;
}

.icon-md {
	font-size: 1.25rem;
}

//util classes
.cursor-pointer {
	cursor: pointer;
}
.uppercase {
	text-transform: uppercase;
}
.w-full {
	width: 100%;
}

// Responsive breakpoints
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

:export {
	xs: $xs;
	sm: $sm;
	md: $md;
	lg: $lg;
	xl: $xl;
}
