.description-switch.description-switch {
	flex-direction: row;
	align-items: center;
	.ant-form-item-control-input-content {
		display: flex;
		.switch-description {
			flex: 1;
			color: #00000073;
			;
		}
	}
}