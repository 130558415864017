.header-layout {
	background-color: #F4F5F7;
	min-height: 100vh;
	.layout-header {
		background-color: #fff;
		box-shadow: 0px 1px 4px 0px #0000000D;
		margin-bottom: 21px;
		padding: 22px 24px;
		.layout-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			h2 {
				margin-bottom: 0;
			}
			.ant-breadcrumb {
				.ant-breadcrumb-link {
					font-weight: 500;
					font-size: 21px;
				}
				li:not(:last-child) .ant-breadcrumb-link a:hover {
					color: #40a9ff;
				}
			}
		}
		.layout-tabs {
			margin-top: 16px;
			.ant-tabs-nav {
				margin-bottom: 0;
			}
		}
	}
	
	&-content {
		padding: 0 21px 20px;
	}
}