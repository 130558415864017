.box-list {
  background: #fff;
  .box-search {
    padding-bottom: 13px;
    align-items: center;
    display: flex;

    input {
      height: 32px;
    }
    button {
      margin-left: 12px;
      width: 40px;
      height: 40px;
    }
  }
  .table-fullscreen {
    background-color: white;
    overflow: auto;
  }
  .table-fullscreen.fullscreen-enabled {
    padding: 0 10px 20px;
  }
  .box-shadow {
    margin-bottom: 20px;
    padding: 0 10px;

    .title-limit {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      word-break: break-word;
    }

    .action-icon {
      cursor: pointer;
      font-size: 22px;
      display: flex;
      justify-content: center;
    }
    .action {
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      .action-all {
        display: flex;
        gap: 15px;
        > button.ant-btn {
          text-transform: uppercase;
        }
      }
      .btn-action {
        padding: 0 10px;
        border: 0;
        background: none;
        cursor: pointer;
        font-size: 20px;
        color: #000;
        .export-icon {
          position: relative;
          top: -3px;
        }
      }
      .btn-action:last-child {
        padding-right: 0;
      }
    }
    .ellipsis {
      font-size: 25px;
      color: #000;
      cursor: pointer;
    }
  }
}

// Other
.overlayActionContent .ant-popover-inner-content {
  padding: 0 !important;
  .action-content {
    display: flex;
    flex-direction: column;
    button {
      padding: 20px 38px;
      display: flex;
      align-items: center;
      border: none !important;
      border: 1px solid transparent !important;
      color: #000;
      border-radius: 0 !important;
    }
    button:hover {
      border: 1px solid #0077c8 !important;
    }
  }
}
